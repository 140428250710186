// vuex store for portal application
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user_info: {},
		user_is_instructor: false,
		user_is_student: false,
		user_is_admin: false,
		google_client_id: '',
		activities: [],
		lti_form: '',
		activity_title: '',	// we get this and the next param back before logging in for an lti link to a specific activity
		student_access_policy: '',
		open_to_exercise_bank: '',

		teacher_collections: [],	// collections of type 'teacher', which are used to share activities across teachers
		groups: [],					// collections of type 'student', which are used to distribute activities to students; for historical reasons we just call these "groups"
		featured_collection_id: null,
		featured_collection_title: '',	// we get this back before logging in for an lti link to a specific activity
		featured_collection_type: '',	// we may get this to indicate whether or not the user can enter an access code to view the activities
		featured_collection_creator_displayname: '',
		demo_collection: null,

		grades: [],
		subjects: {},

		activity_labels: [],

		site_config: {},	// initially set to value in default_site_config.js; can be overwritten via server config
		stylesheets: [],	// we can define custom stylesheets for flavors and/or sparkl servers

		// "local_storage settings": set defaults here; lst_initialize is called on initialization; call lst_set to set new values, possibly in computed:
		// collection_type_showing: {
		// 	get() { return this.$store.state.lst.collection_type_showing },
		// 	set(val) { this.$store.commit('lst_set', ['collection_type_showing', val]) }
		// },
		// @update:collection_type_showing="(val)=>collection_type_showing=val"
		lst: {
			dark_mode: true,
			no_sign_in_mode_role: 'student',
			sign_in_name: '',
		},
		lst_prefix: 'sparkl_local_storage_setting_',
		// note that we share lst across portal, teacher, and student apps
	},
	getters: {
		signed_in:(state) => { return !empty(state.user_info.email) },
		lti_link_id:(state) => { return state.user_info.lti_link_id },
		context_gc:(state, getters) => {
			return document.location.search.indexOf('gc') > -1
		},
		context_lti:(state, getters) => {
			return state.user_info.lti_link_id > 0
		},
		context_standalone:(state, getters) => {
			return !getters.context_gc && !getters.context_lti
		},
		dark_mode_available:(state, getters) => {
			// if we're in an iframe or embed_mode, always use light mode
			if (getters.in_iframe) return false
			if (state.embedded_mode) return false
			return state.site_config.dark_mode_available
		},
		dark_mode:(state, getters) => {
			// if dark_mode is not available, always use light mode; otherwise use what's in state.lst.dark_mode
			if (!getters.dark_mode_available) return false
			return state.lst.dark_mode 
		},
		app_noun:(state) => {
			return state.site_config.app_noun
		},
		bot_noun:(state) => {
			// bot_noun may be explicitly configured; if not use app_noun + '-BOT'
			if (state.site_config.bot_noun) return state.site_config.bot_noun
			else return state.site_config.app_noun + '-BOT'
		},
		// if this is true, this is an "item bank resource", not an activity that will be delivered directly to students
		is_bravo: (state) => {
			return state.open_to_exercise_bank == 'bravo'
		},
	},
	mutations: {
		set(state, payload) {
			// this.$store.commit('set', ['key', val])
			// update state property 'key' to value 'val'
			if (payload.length == 2) {
				state[payload[0]] = payload[1]
				return
			}

			var o = payload[0]
			var key = payload[1]
			var val = payload[2]

			// note below that if the key-d property didn't already exist, we have to Vue.set it

			// this.$store.commit('set', ['obj', 'key', val])
			// update property 'key' of 'o' to value 'val'
			if (typeof(o) == 'string') {
				if (state[o][key] == undefined) Vue.set(state[o], key, val)
				else state[o][key] = val
				return
			}

			// this.$store.commit('set', [arr, 3, 'foo'])	// set value 3 of array arr to value 'foo'
			// this.$store.commit('set', [arr, 'PUSH', 'foo'])	// push 'foo' onto array arr
			// this.$store.commit('set', [arr, 'UNSHIFT', 'foo'])	// unshift 'foo' onto array arr
			// this.$store.commit('set', [arr, 'SPLICE', 1])	// splice index 1 from array
			// this.$store.commit('set', [arr, 'SPLICE', 1, 'foo'])	// splice index 1 from array, replacing it with 'foo'
			if (Array.isArray(o)) {
				if (key == 'PUSH') {
					o.push(val)
				} else if (key == 'UNSHIFT') {
					o.unshift(val)
				} else if (key == 'SPLICE') {
					// if we got a fourth value in payload, add that value into the array; otherwise just take the val-th item out
					if (!empty(payload[3])) {
						o.splice(val, 1, payload[3])
					} else {
						o.splice(val, 1)
					}
				} else {
					// key must be a number; we always have to use Vue.set for setting array values (https://medium.com/@miladmeidanshahi/update-array-and-object-in-vuejs-a283983fe5ba)
					Vue.set(o, key, val)
				}

			// this.$store.commit('set', [obj, 'key', true])
			// this.$store.commit('set', [obj, ['level_1_key', 'level_2_key'], true])
			// update property of obj, **WHICH MUST BE PART OF STATE!**
			} else if (typeof(key) == 'string') {
				if (o[key] == undefined) {
					Vue.set(o, key, val)
				} else {
					o[key] = val
				}

			} else {
				for (var i = 0; i < key.length-1; ++i) {
					o = o[key[i]]
					if (empty(o)) {
						console.log('ERROR IN STORE.SET', key, val)
						return
					}
				}
				if (o[key[i]] == undefined) Vue.set(o, key[i], val)
				else o[key[i]] = val
			}

			// samples:
			// this.$store.commit('set', [this.exercise, ['t', 'editing'], true])
			// this.$store.commit('set', [this.qstatus, 'started', true])
		},

		// fns to initialize and set local_storage settings
		lst_initialize(state) {
			for (let key in state.lst) {
				let val = U.local_storage_get(state.lst_prefix + key)
				if (!empty(val)) {
					state.lst[key] = val
				}
			}
		},

		// this.$store.commit('lst_set', ['mc_mode', 'bubbles'])
		lst_set(state, payload) {
			let key, val
			if (typeof(payload) == 'string') {
				// if a single string value is sent in, we just save in local_storage; presumably the changed value will have been already saved via set
				U.local_storage_set(state.lst_prefix + payload, state.lst[payload])
			}

			if (Array.isArray(payload)) {
				key = payload[0]
				val = payload[1]
			} else {
				key = payload.key
				val = payload.val
			}

			// save in state
			state.lst[key] = val

			// now save in local_storage
			U.local_storage_set(state.lst_prefix + key, val)
		},

		lst_clear(state, key) {
			U.local_storage_clear(state.lst_prefix + key)
		},
	},
	actions: {
		// portal can launch with one of the following forms of url:
		//		sparkl-ed.com				- sparkl portal
		// 		sparkl-ed.com?a=456			- show activity_id 456
		//      sparkl-ed.com/456           - show activity_id 456
		//      sparkl-ed.com/456-demo      - show activity_id 456 in demo mode
		//      sparkl-ed.com/456-demo?xxx  - show activity_id 456 in demo mode, and with other query parameters (e.g. an exercise id)
		//      sparkl-ed.com/456-T08309    - show activity_id 456, to a student associated with the teacher with user ID 8309
		initialize_portal({state, commit, dispatch}, payload) {
			// initially set site_config to window.default_site_config; then this may be overridden by what comes in from the server
			state.site_config = window.default_site_config
			return new Promise((resolve, reject)=>{
				let o = Object({});
				// send the search string (sans the opening ?) through; see initialize_portal.php for how these values are used
				o.query_string = window.location.search.replace(/^\?/, '')

				// if we received an activity_id in the search string, send it through
				if (vapp.activity_id > 0) {
					o.activity_id = vapp.activity_id

					// if the path includes '-demo', launch in demo mode
					if (window.location.pathname.search(/^\/\d+-demo$/i) > -1) {
						o.demo_mode = 'on'

					// else if the path also gives a collection access code (e.g. "T08309" or "ZEJKE"), send that through too
					} else if (window.location.pathname.search(/^\/\d+-(\w+)/) > -1) {
						o.collection_access_code = RegExp.$1

					}
				}

				// if payload contains an id_token, send it in to the service
				if (!empty(payload) && !empty(payload.id_token)) {
					o.id_token = payload.id_token
				}

				// if payload includes 'show_demo_activities' value, send it through
				if (!empty(payload) && !empty(payload.show_demo_activities)) {
					o.show_demo_activities = payload.show_demo_activities
				}

				// if payload includes 'launch_without_signing_in' value, send it through
				if (!empty(payload) && !empty(payload.launch_without_signing_in)) {
					o.launch_without_signing_in = payload.launch_without_signing_in
				}

				// if payload includes 'sign_in_name' value, send it through
				if (!empty(payload) && !empty(payload.sign_in_name)) {
					o.sign_in_name = payload.sign_in_name
				}

				U.ajax('initialize_portal', o, result=>{
					if (result.status != 'ok') {
						// if status isn't 'ok', we will show the teacher an error message
						resolve(result.status)
						return
					}
					console.log('Initialized!', result)

					// store stylesheets if we got them
					if (result.stylesheets) commit('set', ['stylesheets', result.stylesheets])
					
					// store site_config vals if we got them, then initialize stuff based on site_config
					if (result.site_config) {
						// set property-by-property, so that we retain default values set above
						for (let key in result.site_config) {
							commit('set', [state.site_config, key, result.site_config[key]])
						}
						window.initialize_site_config()
					}
					
					// remove 'sparkl-portal' from the url -- so https://sparkl-ed.com/sparkl-portal/?foo# becomes https://sparkl-ed.com/?foo#
					// also normalize collection urls -- so https://sparkl-ed.com/?c=34 becomes https://sparkl-ed.com/c/34
					let url = (window.location + '').replace(/\/sparkl-portal/, '')
					if (url.indexOf('localhost') == -1) url = url.replace(/\?c=(\w+)/, 'c/$1?')	// don't do this when working locally
					url = url.replace(/\?$/, '')
					window.history.replaceState(null, '', url)

					// if we didn't get a proper account in an lti launch (i.e. we didn't get an email or name), don't complete the lti launch even if we got an lti_form; instead stop here and give an appropriate error
					if (result.user_info?.lti_link_id > 0) {
						if (result.user_info.email == 'unknown@school.edu') {
							resolve('no_email_in_lti_launch-' + result.user_info.organization_id)
							return
						} else if (result.user_info.name_family == 'XXXUNKNOWNXXX') {
							resolve('no_name_in_lti_launch-' + result.user_info.organization_id)
							return
						}
					}

					// if we're being directed to show an activity...
					if (!empty(result.lti_form)) {
						commit('set', ['lti_form', result.lti_form])
						resolve('launch_activity')
						return
					}

					// result should always include the google_client_id
					commit('set', ['google_client_id', result.google_client_id])

					// featured_collection_title will come in if a collection is specified in the URL; featured_collection_type may also come in if relevant
					if (!empty(result.featured_collection_title)) {
						commit('set', ['featured_collection_title', result.featured_collection_title])
					}
					if (!empty(result.featured_collection_type)) {
						commit('set', ['featured_collection_type', result.featured_collection_type])
					}
					if (!empty(result.featured_collection_creator_displayname)) {
						commit('set', ['featured_collection_creator_displayname', result.featured_collection_creator_displayname])
					}

					// if we didn't receive user_info, the user is not already logged in...
					if (empty(result.user_info)) {
						// so unless we're showing demo activities or a featured collection...
						if (o.show_demo_activities != 'true' && !result.featured_collection_title) {
							// store activity_title/student_access_policy/open_to_exercise_bank if we got them
							if (!empty(result.activity_title)) {
								commit('set', ['activity_title', result.activity_title])
								commit('set', ['student_access_policy', result.student_access_policy])
								commit('set', ['open_to_exercise_bank', result.open_to_exercise_bank])
							}

							// if we're logging right into an activity, normalize the url to what the server says it should be
							if (!empty(o.activity_id)) {
								U.normalize_url(o.activity_id, result.normalized_url)
							}

							resolve('login')
							return
						}
					} else {
						// set user_info in store
						commit('set', ['user_info', result.user_info])

						commit('set', ['user_is_instructor', result.user_info.role >= 1000])
						commit('set', ['user_is_student', !empty(result.user_info.email) && result.user_info.role < 1000])

						commit('set', ['user_is_admin', (result.user_info.role >= 5000)])

						try {
							// if this window opened from the sparkl portal (e.g. we launched an activity from the portal)
							// and the opener didn't have user_info set
							if (window.opener.vapp && empty(window.opener.vapp.user_info.email)) {
								// then if the user is a teacher, reload the page so they see their activities in the portal
								if (state.user_is_instructor) {
									window.opener.location.reload()
								} else {
									// otherwise just set user info in the opener
									window.opener.U.set_user_info(result.user_info)
									window.opener.vapp.$store.commit('set', ['user_info', state.user_info])
									window.opener.vapp.$store.commit('set', ['user_is_instructor', state.user_is_instructor])
									window.opener.vapp.$store.commit('set', ['user_is_admin', state.user_is_admin])
								}
							}
						} catch(e) {}
					}
										
					// store grades and subjects
					commit('set', ['grades', result.grades])
					commit('set', ['subjects', result.subjects])

					// if we passed an id_token, the user just logged in, so reset collections and activities
					if (!empty(o.id_token)) {
						commit('set', ['groups', []])
						commit('set', ['teacher_collections', []])
						commit('set', ['demo_collection', null])
						commit('set', ['activities', []])
					}

					// save collections in teacher_collections[] and groups[]
					for (let collection_data of result.collections) {
						if (collection_data.type == 'student') {
							commit('set', [state.groups, 'PUSH', new Collection(collection_data)])
						} else {
							commit('set', [state.teacher_collections, 'PUSH', new Collection(collection_data)])
						}
					}

					// if we loaded the demo collection, load it (we'll load the collection's activities below)
					if (result.demo_collection) {
						commit('set', ['demo_collection', new Collection(result.demo_collection)])
					}

					// save activities
					for (let activity_data of result.activities) {
						// make sure we don't load the same activity twice
						if (state.activities.find(x=>x.activity_id == activity_data.activity_id)) continue

						commit('set', [state.activities, 'PUSH', new Activity(activity_data)])
					}

					// note if we received a featured_collection_id
					if (result.featured_collection_id) commit('set', ['featured_collection_id', result.featured_collection_id])

					// if we get to here we resolve with 'collections', but App.vue probably won't show the old collections interface anymore...
					resolve('collections')
				});
			})
		},

		// this.$store.dispatch('add_activity', {title:'Untitled Activity'})
		// specify other activity_data properties in argument as well if desired
		add_activity({state, commit, dispatch}, activity_data) {
			return new Promise((resolve, reject)=>{
				if (empty(activity_data)) activity_data = {}

				let temp_activity = new Activity(activity_data)

				// send the activity data json stringified so the transfer is more efficient
				let s = JSON.stringify(temp_activity.record_for_save())

				U.ajax('save_activity', {activity_data: s, user_id: state.user_info.user_id}, result=>{
					if (result.status != 'ok') {
						console.log('Error adding activity')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject()
						return
					}

					// add "mine" label to the activity data, so it shows up in the mine category
					result.activity_data.labels = ['mine']

					state.activities.push(new Activity(result.activity_data))

					// send added activity to callback fn
					resolve(state.activities[state.activities.length-1])
				});
			})
		},

		// this.$store.dispatch('save_activity', {activity_id: 123, title:'Foo Bar'})
		// activity_id is required; specify other activity_data properties in argument as well if desired
		save_activity({state, commit, dispatch}, activity_data) {
			return new Promise((resolve, reject)=>{
				if (empty(activity_data) || empty(activity_data.activity_id)) {
					console.log('activity_id not specified')
					reject()
				}

				// we have to pass 'unchanged' in for activity_data.exercises to ensure that we don't change the exercises field of the activity, which we don't retrieve in the portal app
				activity_data.exercises = 'unchanged'

				// send the activity data json stringified so the transfer is more efficient
				let s = JSON.stringify(activity_data)

				U.ajax('save_activity', {activity_data: s, user_id: state.user_info.user_id}, result=>{
					if (result.status != 'ok') {
						console.log('Error updating activity')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject()
						return
					}

					let activity = state.activities.find(o=>o.activity_id == activity_data.activity_id)
					for (let key in activity_data) {
						commit('set', [activity, key, activity_data[key]])
					}

					resolve()
				});
			})
		},

		configure_lti_link({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				// payload should include activity_id, which can be 0 for a new activity
				// add is_template:'yes' if we're duplicating a template
				// add lti_link_id and editor_email to payload and send it in
				payload.lti_link_id = this.getters.lti_link_id
				payload.editor_email = state.user_info.email
				U.ajax('configure_lti_link', payload, result=>{
					if (result.status == 'activity_does_not_exist') {
						reject('activity_does_not_exist')
						return

					} else if (result.status != 'ok') {
						console.log('Error configuring lti link')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject(result.status)
						return
					}

					// if successful, redirect to the returned activity_id, passing along the PHPSESSID; this will cause the link to re-launch to the configured activity
					document.location = vapp.activity_url(result.activity_id) + document.location.search
				});
			})
		},

		// this.$store.dispatch('duplicate_activity', {activity_id: 123, title:'Foo Bar'})
		// add is_template:'yes' if we're duplicating a template
		duplicate_activity({state, commit, dispatch}, payload) {
			// when we do this from the portal, we always want to allow multiple duplications, so...
			payload.skip_previous_duplicate_check = 'yes'
			return new Promise((resolve, reject)=>{
				U.ajax('duplicate_activity', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error duplicating activity')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject()
						return
					}

					// add "mine" label to the link data, so it shows up in the mine category
					result.activity_data.labels = ['mine']
					state.activities.push(new Activity(result.activity_data))

					// send added activity to callback fn
					resolve(state.activities[state.activities.length-1])
				});
			})
		},

		// this.$store.dispatch('delete_activity', activity_id)
		delete_activity({state, commit, dispatch}, activity_id) {
			return new Promise((resolve, reject)=>{
				U.ajax('delete_activity', {activity_id: activity_id}, result=>{
					if (result.status != 'ok') {
						console.log('Error deleting activity')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject()
						return
					}

					// remove from state.activities
					let i = state.activities.findIndex(x=>x.activity_id==activity_id)
					if (i > -1) commit('set', [state.activities, 'SPLICE', i])

					// and remove from all collections
					for (let c of state.groups) {
						let i = c.activities.findIndex(x=>x.activity_id==activity_id)
						if (i > -1) commit('set', [c.activities, 'SPLICE', i])
					}
					for (let c of state.teacher_collections) {
						let i = c.activities.findIndex(x=>x.activity_id==activity_id)
						if (i > -1) commit('set', [c.activities, 'SPLICE', i])
					}

					resolve()
				});
			})
		},

		save_collection({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				// payload should include parameters that tell the service what to save (including a mandatory collection_id); add teacher_id, then send
				// note that we also use this service to delete collections
				payload.teacher_id = state.user_info.user_id

				U.loading_start()
				U.ajax('save_collection', payload, result=>{
					U.loading_stop()
					if (result.status == 'emails_dont_exist' || result.status == 'user_not_teacher') {
						// in these cases, reject with the returned result, which should include bad_emails
						reject(result)
						return

					} else if (result.status != 'ok') {
						console.log('Error saving collection')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject()
						return
					}

					// if we just deleted the collection, delete from state and resolve
					if (payload.delete_collection == 'yes') {
						let arr = (payload.type == 'student') ? state.groups : state.teacher_collections
						let index = arr.findIndex(x=>x.collection_id == payload.collection_id)
						commit('set', [arr, 'SPLICE', index])
						resolve()
						return
					}

					// else result will include collection, which has the full data for the collection; add or update the collection in store
					let c = new Collection(result.collection)
					let arr = (c.type == 'student') ? state.groups : state.teacher_collections
					if (payload.collection_id == -1) {
						commit('set', [arr, 'PUSH', c])
					} else {
						let index = arr.findIndex(x=>x.collection_id == payload.collection_id)
						commit('set', [arr, 'SPLICE', index, c])
					}

					// resolve with the collection
					resolve(c)
				});
			})
		},

		subscribe_to_collection({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				// payload must include access_code and can optionally include collection_id; add user_id to payload
				payload.user_id = state.user_info.user_id

				U.loading_start()
				U.ajax('subscribe_to_collection', payload, result=>{
					U.loading_stop()
					// service may return an error code; if so return to the caller
					if (result.status != 'ok') {
						console.log('Error verifying access code (teacher)')
						// call ping to check if the session is expired (which might be problem, but probably not)
						vapp.ping()

						reject(result.status)
						return
					}

					// add returned collection and activities to state
					// the collection definitionally cannot have been previously added
					if (result.collection.type == 'student') {
						commit('set', [state.groups, 'PUSH', new Collection(result.collection)])
					} else {
						commit('set', [state.teacher_collections, 'PUSH', new Collection(result.collection)])
					}

					// activities might have already been added, so we have to be careful about adding them here
					for (let a of result.activities) {
						if (state.activities.findIndex(x=>x.activity_id==a.activity_id) == -1) {
							commit('set', [state.activities, 'PUSH', new Activity(a)])
						}
					}

					resolve()
				});
			})
		},

		unsubscribe_to_collection({state, commit, dispatch}, collection_id) {
			return new Promise((resolve, reject)=>{
				let o = {
					collection_id: collection_id,
					user_id: state.user_info.user_id,
				}

				U.loading_start()
				U.ajax('unsubscribe_to_collection', o, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error unsubscribing code')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject(result.status)
						return
					}

					// remove the collection; could be in teacher_collections or groups; but first get the list of activities
					let activities
					let i = state.teacher_collections.findIndex(x=>x.collection_id == collection_id)
					if (i > -1) {
						activities = state.teacher_collections[i].activities
						commit('set', [state.teacher_collections, 'SPLICE', i])
					} else {
						let i = state.groups.findIndex(x=>x.collection_id == collection_id)
						if (i > -1) {
							activities = state.groups[i].activities
							commit('set', [state.groups, 'SPLICE', i])
						}
					}

					// if we removed a collection, look through each of its activities
					if (activities) {
						for (let a of activities) {
							// see the activity isn't in any other group or collection, remove it
							let included = false
							for (let c of state.teacher_collections) {
								if (c.activities.find(x=>x.activity_id == a.activity_id)) {
									included = true
									break
								}
							}
							if (!included) for (let c of state.groups) {
								if (c.activities.find(x=>x.activity_id == a.activity_id)) {
									included = true
									break
								}
							}

							// if not included in another group, remove it
							if (!included) {
								let i = state.activities.findIndex(x=>x.activity_id == a.activity_id)
								if (i > -1) commit('set', [state.activities, 'SPLICE', i])
							}
						}
					}

					resolve()
				});
			})
		},

		mimic_user({state, commit, dispatch}, payload) {
			return new Promise((resolve, reject)=>{
				U.loading_start()
				U.ajax('mimic_user', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error mimicking user')

						// call ping to check if the session is expired (presumably that's the problem)
						vapp.ping()

						reject(result.status)
						return
					}

					resolve()
				});
			})
		},

		// this.$store.dispatch('sign_out')
		sign_out({state, commit, dispatch}) {
			return new Promise((resolve, reject)=>{
				U.ajax('sign_out', {}, result=>{
					if (result.status != 'ok') {
						vapp.$alert('Error signing out!')
						reject()
						return
					}

					// clear session ids, so that the user is also signed out of any activities they opened
					U.clear_all_session_ids()

					// reload the page, which should show the google login button
					document.location.reload()
				});
			})
		},

	}
})
