<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-dialog v-model="dialog_open" light overlay-opacity="0.95" @input="$emit('dialog_done')">
		<v-card class="k-activity-link-dialog-card">
			<div style="position:absolute; right:4px; top:4px"><v-btn small fab color="#333" @click="$emit('dialog_done')"><v-icon color="#fff">fas fa-xmark</v-icon></v-btn></div>
			<v-card-title class="text-center" style="line-height:1.3em; padding-right:44px; padding-left:44px;">
				<v-spacer />
				<a :href="activity_link" target="_blank" style="text-decoration:none"><b v-html="activity_title"></b></a>
				<v-spacer />
			</v-card-title>
			<v-card-text>
				<div style="max-width:800px" class="mx-auto mb-4 text-center">This activity is accessible from the following URL:</nobr></div>
				<div style="font-size:1.5em" class="d-flex align-center">
					<qrcode-vue :value="activity_link" size="160" level="H" />
					<v-text-field rounded background-color="green darken-3" class="ml-6 mr-3" dense hide-details id="activity_link_link" :value="activity_link">{{activity_link}}</v-text-field>
					<v-btn fab small color="primary" @click="copy_activity_link_to_clipboard"><v-icon>fas fa-clipboard</v-icon></v-btn>
				</div>
				<!-- <div class="text-right mb-1 mr-13"><v-btn small color="#999" text class="k-tight-btn" @click="demo_link_showing=!demo_link_showing">Show {{demo_link_showing?'Student':'Demo'}} Link</v-btn></div> -->

				<div style="max-width:800px" class="mx-auto mb-4"><ul>
					<li>To use the activity with <b>Google Classroom</b>, add a GC assignment with this URL. After clicking the link, students will sign in to {{app_noun}} with Google, then verify that you are their teacher. You will be able to see their activity results and adjust grades for constructed responses.</li>
					<li>If you’re using an <b>LMS</b> (e.g. Canvas or Schoology), you may be able to set {{app_noun}} up as an LTI tool, which will allow for single-sign-in from the LMS and grade passback to the LMS. Or you can add the activity URL as a link in an LMS assignment; in this case, students will need to sign in to {{app_noun}} with Google, then verify that you are their teacher.</li>
				</ul></div>
			</v-card-text>
			<v-card-actions class="pt-2">
				<v-spacer/>
				<v-btn large color="secondary" @click="copy_activity_link_to_clipboard"><v-icon class="mr-2">fas fa-clipboard</v-icon>Copy Link to Clipboard</v-btn>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-6" color="secondary" large @click="add_to_google_classroom"><img src="./google_classroom_logo.png" style="height:16px;border-radius:2px;margin-right:8px;">Add to Google Classroom</v-btn></template><div class="text-center">Click this button to be redirected to a Google Classroom interface<br>where you’ll select a class, then create an assignment linking to the {{app_noun}} activity.</div></v-tooltip>
				<!-- <v-btn large color="primary" @click="$emit('dialog_done')"><v-icon class="mr-2 ml-2">fas fa-check</v-icon><span class="mr-4">Done</span></v-btn> -->
				<v-spacer/>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'

export default {
	components: { QrcodeVue },
	props: {
		activity_id: { required: true },
		activity_title: { required: true },
		gc_share_body: { required: true },
		from_portal: { required: true },
		mine: { required: true },
		access_code: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		show_more_gc_info: false,
		demo_link_showing: false,
	}},
	computed: {
		...mapState(['user_info']),
		...mapState(['app_noun']),
		activity_link() {
			// return 'https://apsources.commongoodlt.com/36197'
			let curr_url = window.location.href;
			let url

			// add demo if demo_link_showing; else add access_code if provided
			// local dev with 'npm run serve'
			if (curr_url.includes('localhost')) {
				// url = 'http://localhost:8072?a=' + this.activity_id
				url = 'http://localhost:8072/' + this.activity_id
				if (this.demo_link_showing) url += '&demo'
				else if (this.access_code) url += '&c=' + this.access_code
			// local testing with 'npm run build'
			} else if (curr_url.includes('local.pw-et.com')) {
				url = 'http://local.pw-et.com/' + this.activity_id
				if (this.demo_link_showing) url += '-demo'
				else if (this.access_code) url += '-' + this.access_code
			// server -- dev or production
			} else {
				url = window.location.origin + '/' + this.activity_id
				if (this.demo_link_showing) url += '-demo'
				else if (this.access_code) url += '-' + this.access_code
			}

			return url
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		copy_activity_link_to_clipboard() {
			let copy_text = $('#activity_link_link')[0]
			copy_text.select()
			document.execCommand("copy")

			this.$inform({text:'Link copied to clipboard'})
		},

		add_to_google_classroom() {
			// https://developers.google.com/classroom/guides/sharebutton
			// see also portalapp / Link.vue -- we want this url to be the same for both of these places
			let url = sr('https://classroom.google.com/share?url=$1&title=$2&body=$3&itemtype=assignment', this.activity_link, this.activity_title, this.gc_share_body)
			window.open(url)
		},
	}
}
</script>

<style lang="scss">
.k-activity-link-dialog-card {
	.v-card__title {
		font-size:36px!important;
		line-height:80px;
	}
	.v-card__text {
		color:#222!important;
		font-size:18px;
		// text-align:center;
	}
	.v-text-field__slot input {
		text-align:center;
		font-size:48px;
		font-weight:bold;
		min-height:120px;
		color:#fff;
	}
}

@media (max-width: 1400px) {
	.k-activity-link-dialog-card {
		.v-text-field__slot input {
			font-size:40px;
			min-height:108px;
		}
	}
}

@media (max-width: 1200px) {
	.k-activity-link-dialog-card {
		.v-text-field__slot input {
			font-size:32px;
			min-height:100px;
		}
	}
}

@media (max-width: 1040px) {
	.k-activity-link-dialog-card {
		.v-text-field__slot input {
			font-size:28px;
			min-height:96px;
		}
	}
}

@media (max-width: 960px) {
	.k-activity-link-dialog-card {
		.v-text-field__slot input {
			font-size:22px;
			min-height:88px;
		}
	}
}

.k-activity-link-google-classroom-info {
	margin:32px auto 0 auto;
	text-align:center;
	font-size:18px;
	line-height:24px;
	max-width:800px;
}
</style>
